import React from 'react';
import { Report } from 'cccisd-laravel-report';
import UnderstandingNotReport from '../Report';

const Component = props => {
    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report downloadFilename="UnderstandingNotReport">
                <UnderstandingNotReport {...props} />
            </Report>
        </div>
    );
};

export default Component;
