import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'flexibility_report',
    label: 'FlexibilityReport',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
