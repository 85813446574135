import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'understanding_not_report',
    label: 'UnderstandingNotReport',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
