import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import devTagsQuery from './devTagsQuery.graphql';
import { client } from 'cccisd-apollo';

// assets
import logo from './assets/Logos/logo.png';
import logo3C from './assets/Logos/3c.png';

const Fortress = window.cccisd.fortress;

const categoryData = {
    Flexible: {
        phrases: [
            `This isn’t what you expected, but if you can stay flexible, you’ll have more choices.`,
            `Let’s think of a way we can be flexible in case things don’t go according to our first plan.`,
            `Being flexible helps me get some of what I want.`,
            `Being flexible feels better than being stuck.`,
            `Thanks for reminding me to be flexible. That helped me calm down.`,
        ],
        devTag: 'FlexibleWords',
        description: `Flexibility makes us stronger and calmer. It also gives us a lot more choices than being stuck.`,
    },
    StuckandUnstuck: {
        phrases: [
            `It looks like you might be stuck. How can I help?`,
            `Are you feeling stuck?`,
            `Great job getting unstuck. Now you have lots of options.`,
            `I am so glad I got unstuck. I feel so much better.`,
            `Can you help me get unstuck?`,
        ],
        devTag: 'StuckWords',
        description: `When we’re stuck, we have to do something different to get unstuck.`,
    },
    PlanAandPlanB: {
        phrases: [
            `What’s your plan A?`,
            `Do you have a plan B if your plan A doesn’t work out?`,
            `I want to make sure we still accomplish our goal. Let’s make a plan B.`,
            `I’m so glad we had a plan B so we didn’t get stuck.`,
            `My plan A isn’t working. I need a plan B.`,
        ],
        devTag: 'PlanWords',
        description: `It’s normal when things don’t go as planned, and Plan Bs help us stay calm when we’re disappointed.`,
    },
    Compromise: {
        phrases: [
            `I’m so glad we compromised. We both got part of what we wanted.`,
            `Thank you for compromising with me. You got some of what you wanted, and I got some of what I wanted.`,
            `Let’s compromise and combine both of our ideas.`,
            `What’s a good compromise?`,
            `Can we choose something else that we both want to do as a compromise?`,
        ],
        devTag: 'CompromiseWords',
        description: `Two people can each get part of what they want, even if they can’t have everything they want right now.`,
    },
    BigDealandLittleDeal: {
        phrases: [
            `I can tell this feels like a big deal. Let’s find a way to make it a little deal.`,
            `Turning the big deal into a little deal made you feel better and gave you more choices.`,
            `Is this a big deal or a little deal?`,
            `I know this feels like a big deal. Let me know when we can work together to make a plan.`,
            `I could tell this felt like a big deal to you, but you stayed calm and worked with me to make it a little deal. You’re a terrific problem solver.`,
        ],
        devTag: 'DealWords',
        description: `Categorize life events as either big deals or little deals to help figure out ways to turn a big deal into a little deal.`,
    },
    ChoiceandNoChoice: {
        phrases: [
            `I really don’t want to do this, but I know it’s a no-choice situation so I’ll get it over with.`,
            `This is a choice situation so I can decide.`,
            `You have a choice here. What choice will you make?`,
            `This is a no-choice situation. Let’s just do it so we can go back to having fun.`,
            `I think you have three different choices you can make right now! Can you tell me what they are?`,
        ],
        devTag: 'ChoiceWords',
        description: `Choice and No Choice help children establish a framework for when they can negotiate and when they have to go with the flow.`,
    },
};

const FlexibilityReport = props => {
    const { settings } = props;

    const [devTagsData, setDevTagsData] = useState({});

    // inserting initialValues for the case that they don't yet exist through settings (Report GUI builder in app settings)
    for (const key of Object.keys(initialValues)) {
        if (!(key in settings)) {
            settings[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in settings[key])) {
                    settings[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: devTagsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                },
            });

            let queryData = {};
            queryData = result.data.flows.assignmentProgress.devTags;

            setDevTagsData(queryData);
        };

        if (!props.isPreview) {
            getMetricsData();
        }
    }, []);

    return (
        <>
            {settings.page1.show && <Page1 {...settings.page1} devTagsData={devTagsData} />}
            {settings.page2.show && <Page2 {...settings.page2} devTagsData={devTagsData} />}
        </>
    );
};

const Header = props => {
    return (
        <>
            <div className={style.header_container}>
                <img src={logo} alt="" className={style.header_logo} />
            </div>
            <div className={style.header_line} />
            <HeaderTitle />
        </>
    );
};

const Footer = props => {
    return (
        <div className={style.footer_container}>
            <span className={style.footer_text}>Copyright {new Date().getFullYear()}</span>
            <img src={logo3C} alt="" className={style.footer_img} />
        </div>
    );
};

const HeaderTitle = () => {
    return (
        <div className={style.title}>
            <span className={style.color_green}>Words That Build Flexibility</span>
        </div>
    );
};

const CategorySection = props => {
    const { devTagsData, title } = props;
    const dataKey = title.replaceAll(' ', '');
    const { description, phrases, devTag } = categoryData[dataKey];
    const response = devTagsData[devTag];

    let textArea = <CategoryGridRow leftText={phrases} response={response} />;

    return (
        <>
            <div className={style.green_box_section}>
                <div className={style.section_title}>{title}:</div>
                <div style={{ width: '100%', fontWeight: '300' }}>{description}</div>
            </div>
            <div className={style.section_grid_container}>
                <div className={style.section_grid_row}>
                    <div className={style.dark_gray}>
                        {' '}
                        <div className={style.category_row_description}>Phrases to help you model</div>
                    </div>
                    <div className={style.dark_gray}>
                        {' '}
                        <div className={style.category_row_description}>
                            A situation where you might find these words helpful:
                        </div>
                    </div>
                </div>
                {textArea}
            </div>
        </>
    );
};

const CategoryGridRow = props => {
    const { leftText, response } = props;

    return (
        <div className={style.category_data_row}>
            <div style={{ background: 'white' }}>
                {' '}
                <div style={{ padding: '5px', display: 'grid', gap: '5.5px', fontWeight: '300', fontSize: '13px' }}>
                    {leftText.map(sentence => (
                        <div>{`“${sentence}”`}</div>
                    ))}
                </div>
            </div>
            <div style={{ background: 'white' }}>
                {' '}
                <div style={{ padding: '5px' }}>{response}</div>
            </div>
        </div>
    );
};

const Page1 = props => {
    const { devTagsData } = props;

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox />
                <div style={{ marginTop: '15px' }}>
                    {`Include these words in your daily language to model flexibility and encourage your child to build their flexibility skills.`}
                </div>
                <CategorySection devTagsData={devTagsData} title="Flexible" />
                <CategorySection devTagsData={devTagsData} title="Stuck and Unstuck" />
                <CategorySection devTagsData={devTagsData} title="Plan A and Plan B" />
                <Footer />
            </div>
        </Page>
    );
};

const Page2 = props => {
    const { devTagsData } = props;

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox />
                <CategorySection devTagsData={devTagsData} title="Compromise" />
                <CategorySection devTagsData={devTagsData} title="Big Deal and Little Deal" />
                <CategorySection devTagsData={devTagsData} title="Choice and No Choice" />
                <Footer />
            </div>
        </Page>
    );
};

FlexibilityReport.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

FlexibilityReport.defaultProps = {};

export default FlexibilityReport;
