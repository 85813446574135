import { AnswerSummary } from 'cccisd-laravel-appdefs/dist/reportTemplates';
import ExecutiveFunctionReport from './ExecutiveFunctionReport';
import UnderstandingNotReport from './UnderstandingNotReport';
import AccommodationsReport from './AccommodationsReport';
import FlexibilityReport from './FlexibilityReport';

export default [
    AnswerSummary,
    ExecutiveFunctionReport,
    UnderstandingNotReport,
    AccommodationsReport,
    FlexibilityReport,
];
